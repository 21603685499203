<template>
    <div class="colorpicker" :class="{'colorpicker--required': required}">
        <input
            :id="id"
            ref="input"
            v-bind="$attrs"
            :name="name"
            :required="required"
            :value="value"
            class="colorpicker__input"
            tabindex="-1"
            type="text"
            @input="$event.target.value = ''"
        >
    </div>
</template>

<script>
    import $ from 'jquery';
    import 'spectrum-colorpicker';

    export default {
        inheritAttrs: false,

        model: {
            prop: 'value',
            event: 'change',
        },

        props: {
            id: {
                type: String,
                default: null,
            },

            name: {
                type: String,
                default: null,
            },

            value: {
                type: String,
                default: null,
            },

            required: {
                type: Boolean,
                default: false,
            },
        },

        watch: {
            value(value) {
                this.$options.input.spectrum('set', value);
            },
        },

        mounted() {
            this.$options.input = $(this.$refs.input);
            this.$options.input.spectrum({
                cancelText: this.$t('form.colorpicker.cancel'),
                chooseText: this.$t('form.colorpicker.select'),
                color: this.value,
                preferredFormat: 'hex',
                showButtons: true,
                showInput: true,
                allowEmpty: true,
                showPalette: true,
                clickoutFiresChange: true,
                change: (color) => {
                    if (color === null) {
                        this.$emit('change', null);

                        return;
                    }

                    const hexValue = color.toHexString();
                    if (hexValue !== this.value) {
                        this.$emit('change', hexValue);
                    }
                },
                palette: [
                    ['#000000', '#444444', '#666666', '#999999', '#cccccc', '#eeeeee', '#f3f3f3', '#ffffff'],
                    ['#f4cccc', '#fce5cd', '#fff2cc', '#d9ead3', '#d0e0e3', '#cfe2f3', '#d9d2e9', '#ead1dc'],
                    ['#ea9999', '#f9cb9c', '#ffe599', '#b6d7a8', '#a2c4c9', '#9fc5e8', '#b4a7d6', '#d5a6bd'],
                    ['#e06666', '#f6b26b', '#ffd966', '#93c47d', '#76a5af', '#6fa8dc', '#8e7cc3', '#c27ba0'],
                    ['#cc0000', '#e69138', '#f1c232', '#6aa84f', '#45818e', '#3d85c6', '#674ea7', '#a64d79'],
                    ['#990000', '#b45f06', '#bf9000', '#38761d', '#134f5c', '#0b5394', '#351c75', '#741b47'],
                    ['#660000', '#783f04', '#7f6000', '#274e13', '#0c343d', '#073763', '#20124d', '#4c1130'],
                ],
            });
        },

        beforeDestroy() {
            this.$options.input.spectrum('destroy');
        },

        input: null,
    };
</script>
